import { EquipmentList } from '@bbx/common/types/ad-detail/Equipments'
import { AttributesItem } from '@wh/common/chapter/types/Attributes'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'

export const convertSpecialEquipmentsIntoAttributes = (equipmentList: EquipmentList): AttributesItem[] => {
    return equipmentList.equipments
        .map((equipment) => equipmentAttributeNameMappings.find((mapping) => mapping.id === equipment.id))
        .filter(mappingExists)
        .map((mapping) => buildEquipmentAttribute(mapping!.attributeName))
}

const mappingExists = (mapping: EquipmentAttributeNameMapping | undefined) => {
    return !!mapping
}

const buildEquipmentAttribute = (name: AttributeName): AttributesItem => {
    return {
        name,
        values: ['Ja'],
    }
}

interface EquipmentAttributeNameMapping {
    id: string
    attributeName: AttributeName
}

const equipmentAttributeNameMappings: EquipmentAttributeNameMapping[] = [
    { id: '78', attributeName: 'EQUIPMENT_VAT_REPORTABLE' },
    { id: '79', attributeName: 'EQUIPMENT_WHEELCHAIR_ACCESSIBLE' },
    { id: '82', attributeName: 'EQUIPMENT_METALLIC_PAINT' },
    { id: '84', attributeName: 'EQUIPMENT_LEASABLE' },
    { id: '85', attributeName: 'EQUIPMENT_COMPLETE_SERVICE_BOOKLET' },
]
